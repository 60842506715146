import React from 'react'
import styled from 'styled-components'

const Texth3 = styled.h3`

 
`

const Wrapper = styled.button`
    padding-left:20px;
    padding-right:20px;
    padding-top:10px;
    padding-bottom:10px;
    border-radius:30px;
  
    ${props => {
    if (props.dark) {
      return `
        border:1px solid  black;
        transition: 0.5s ease all;
          ${Texth3} {
              color:black; 
              transition: 0.5s ease color;
          }
          &:hover{
            background:black;
            ${Texth3} {
              transition: 0.5s ease color;  
              color:white; 
            }
          }
        `
    }

    return `
      border:1px solid  white;
      transition: 0.5s ease all;
      ${Texth3} {
              color:white; 
              transition: 0.5s ease color;
          }
      &:hover{
        background:white;
        ${Texth3} {
          transition: 0.5s ease color;
          color:black; 
        }
      }
    `
  
  }}
    @media(pointer: coarse),(max-width:768px){
      padding-left:15px;
    padding-right:15px;
    }


`



export default function Button({ text, dark, ...props }) {
  return (
    <Wrapper dark={dark} {...props}>
      <Texth3 dark={dark} className='button'> {text}</Texth3>
    </Wrapper>
  )
}
