import React, { useContext } from 'react'
import Header from './header'
import Menu from './menu'
import { MenuContext } from '../context/menu-provider'

export default function MenuHeader() {

    const menu = useContext(MenuContext)
    const {setOpen} = menu
    return (
        <>
            <Menu open={menu?.menu?.open} setOpen={setOpen} />
            <Header dark={menu?.menu?.dark}  hidden={menu?.menu?.hidden}   open={menu?.menu?.open} setOpen={setOpen}  />
        </>
    )
}
