/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import  React ,{useState} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Menu from "./menu"
import Header from "./header"
import { createGlobalStyle } from "styled-components"
import '../styles/global.scss'
import MenuHeader from "./menu-header"



const Layout = ({ children }) => {

  return (
    <>
        <MenuHeader/>
      
        <main>{children}</main>

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
