import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'
import gsap from 'gsap'
export default function Link({ children, ...props }) {

    const exit = {
        length:1,
        trigger: ({ exit, node }) =>{
            const tl = gsap.timeline()
            tl.to(node,{opacity:0,ease:'ease',duration:1})
        }
    }

    const entry ={
        delay:1.1,
        length:1,
        trigger: ({ exit, node }) =>{
            const tl = gsap.timeline()
            tl.to(node,{opacity:0,ease:'ease',duration:0})
            tl.to(node,{opacity:1,ease:'ease',duration:1})
          

        } 
    }

    return (
        <TransitionLink entry={entry} exit={exit} {...props}>
            {children}
        </TransitionLink>
    )
}
