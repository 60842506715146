import styled from "styled-components"

export const ContentContainer = styled.div`
    padding-left:60px;
    padding-right:60px;
    @media(pointer: coarse),(max-width:768px){
        padding-left:30px;
        padding-right:30px;
    }
`


