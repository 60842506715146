import React from 'react'

export default function SmallLogo() {
  return (
    <svg width="23" height="32" viewBox="0 0 23 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.1235 25.7958V6H0V25.7958V32H6.1235H17V25.7958H6.1235Z" fill="white"/>
    <path d="M16.8812 0H6V6.20421H16.8812V26H23V6.20421V0H16.8812Z" fill="white"/>
    </svg>
  )
}
