import * as React from "react"
import { ContentContainer } from "./styles"
import Logo from "./icons/logo"
import styled from "styled-components"
import MenuButton from "./button"


const Wrapper = styled.header`
  position:fixed ;
  top:0px;
  width:100vw;
  z-index:90;
  padding-top:30px;
  padding-bottom:30px;
  transition: 0.5s ease opacity, 0.5s ease background-color;
  opacity:${props => props.hide ? 0 : 1};
  background-color:${props => props.dark ? 'white' : 'transparent'};
  a{
    transition:0.5s ease fill ;
  }
`

const SubTitle = styled.div`
 left:175px;
 top:0px;
  position:absolute ;    
  z-index:85;
  padding-top:33px;
  padding-bottom:30px;
 
  display :flex ;
  justify-content:center;
 
`

const Header = ({ setOpen, dark, hidden }) => (
  <>
    <SubTitle >
      <h5 className="small-p h-[42px] flex flex-col justify-end pb-[5px]   md:hidden  ">
        Project Management.
      </h5>
    </SubTitle>
    <Wrapper dark={dark} hide={hidden}>
      <ContentContainer>
        <div className="flex justify-between items-center">
          <Logo dark={dark} />
          <MenuButton text='Get in touch' dark={dark} onClick={() => setOpen(true)} />
        </div>
      </ContentContainer>
    </Wrapper>
  </>
)



export default Header
