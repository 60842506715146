import React, { useState } from 'react'
import styled from 'styled-components'
import Button from './button'
import SmallLogo from './icons/small-logo'
import { ContentContainer } from './styles'


const Wrapper = styled.div`
    background-color:#3D3D3D;
   
    height:100vh ;
    max-width:756px;
    right:0px;
    position:fixed ;
    transition .85s cubic-bezier(.77,0,.175,1) 0s;
    top:0px;
    z-index:100;
    min-width:50%;
    transform: ${({ open }) => {
        if (open) {
            return `translate(0%)`
        }
        return `translate(100%)`
    }};
`

const Overlay = styled.div`
    z-index:99;
    background-color:black;
    transition:0.85s ease opacity;
    opacity:${props => props.open ? 0.85 : 0};
    position:absolute ;
    top:0px;
    pointer-events:${props => props.open ? 'all' : 'none'};
    height:100vh;
    width:100vw;
    position:fixed ;
    cursor:pointer;

`

const Input = styled.input.attrs({
    className: 'h4 white',
})`
    &::-webkit-outer-spin-button,&::-webkit-inner-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }
    &[type=number]{
        -moz-appearance:textfield;
    }

    border-bottom:1px solid #9A9A9A;;
    border-radius:0px;
    background-color:transparent;
    padding-bottom:15px;
    outline:none;
    &:focus{
        border-bottom:1px solid #9A9A9A;;
    }
    &::placeholder{
        color:#F3F3F3;
    }
`

const Header = styled.div`
   
    padding-top:30px;
    padding-bottom:30px;
   
    top: 0px;
    display:flex;
    justify-content:space-between;

`



export default function Menu({ open, setOpen }) {

    function close() {
        setOpen(false)
    }

    return (
        <>
            <Overlay onClick={close} open={open} />
            <Wrapper open={open}>
                <ContentContainer className='h-[100%] flex flex-col pb-[60px] overflow-scroll' >
                    <Header>
                        <SmallLogo />
                        <Button onClick={close} text='Close' />
                    </Header>
                    <div className='flex flex-col justify-center flex-grow'>
                        <p className='small-p mt-[40px] max-w-[500px] flex-grow pb-[70px] flex flex-col justify-end'>Our objective is to deliver you the very best in project management. If you have a project or want to simply chat, please contact David via the form below:</p>
                        <Form close={close} />
                        <Footer />
                    </div>

                </ContentContainer>
            </Wrapper>
        </>
    )
}

const ContactForm = styled.form`
    position:relative ;
    transition:1s ease opacity;
    opacity:${props => props.submitted ? 0 : 1};
    pointer-events:${props => props.submitted ? 'none' : 'all'};
`

const Submitted = styled.div`
    position:absolute ;
    transition:1s ease opacity 1s;
    opacity:${props => props.submitted ? 1 : 0};
    pointer-events:${props => props.submitted ? 'all' : 'none'};
`

function Form({ close }) {

    const [submitted, setSubmitted] = useState(false)

    async function submit(e) {
        e.preventDefault()
        const form = e.target

        const formData = new FormData(form);


        if (validateData(formData)) {
            try {
                const result = await fetch("/", {
                    method: "POST",
                    headers: { "Content-Type": "application/x-www-form-urlencoded" },
                    body: new URLSearchParams(formData).toString()
                })

                if (result.status === 404) {
                    throw new Error('404 error');
                } else {
                    setSubmitted(true)
                }

            } catch (e) {
                console.error(e)
                setSubmitted(false)
            }
        }
    }

    function validateData(formData) {
        return formData.get('fName') && formData.get('LName') && formData.get('mail')
    }

    return (
        <div className=' flex-grow flex flex-col justify-start pb-[55px] relative '>
            <ContactForm onSubmit={submit} submitted={submitted} name="contact" method="POST" data-netlify="true" >
                <input type="hidden" name="form-name" value="contact" />
                <div className='grid grid-cols-2 gap-x-[25px] mb-[30px]'>
                    <Input type='text' required={true} name='fName' placeholder='First Name' />
                    <Input type='text' required={true} name='LName' placeholder='Surname' />
                </div>
                <div className='grid grid-cols-2 gap-x-[25px] mb-[30px]'>
                    <Input type='email' required={true} name='mail' placeholder='EMAIL' />
                    <Input type='number' name='phone' placeholder='PHONE' />
                </div>
                <div className='grid grid-cols-1  mb-[60px]'>
                    <Input type='text' name='subject' placeholder='SUBJECT' />

                </div>
                <div className='grid grid-cols-1  mb-[30px]'>
                    <Input type='text' name='message' placeholder='MESSAGE' />

                </div>
                <div className='flex justify-end'>
                    <Button text='Get in touch' />
                </div>
            </ContactForm>
            <Submitted submitted={submitted}>
                <p className='h2 text-white white mb-[40px] max-w-[450px] '>Thank you for your submission. Our team will be in contact shortly.</p>
                <Button onClick={close} text='Go Back' />
            </Submitted>
        </div>
    )
}

function Footer() {
    return (
        <div className='grid grid-cols-2 md:grid-cols-1 '>
            <div className='md:mb-[40px] '>
                <h4 className='small-p'>David Pentland<br />
                    0421 838 343<br />
                    david@formpm.com.au</h4>

            </div>
            <div>
                <h4 className='small-p '>
                    Office <br />
                    204 Latrobe Terrace,
                    <br />
                    Paddington QLD 4064
                </h4>
            </div>

        </div>
    )
}