import React, { useMemo } from 'react'
import styled from 'styled-components'
import Link from '../link'

const Wrapper = styled.div`
    svg {
        transition:0.5s ease fill ;  
    }

`

const ratio = 1.15
export default function Logo({dark}) {

    const fill = useMemo(() =>{
        if(dark) {
            return '#3D3D3D'
        }
        return 'white'
    },[dark])
    

 

    return (
        <Link to='/'>
            <Wrapper>
                <svg width={90 * ratio} height={26 * ratio} viewBox="0 0 90 26" fill={fill} xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <path d="M79.7383 4.69V3.206H80.8653C81.8593 3.206 82.6223 2.695 82.6223 1.624C82.6223 0.504 81.8523 0 80.8653 0H78.9473V4.69H79.7383ZM80.7883 0.7C81.3273 0.7 81.8033 0.896 81.8033 1.624C81.8033 2.303 81.3273 2.506 80.7883 2.506H79.7383V0.7H80.7883Z"  />
                        <path d="M85.4039 4.69V2.485L85.2919 0.938L85.8449 2.282L86.6569 3.899H87.4549L88.2669 2.282L88.8059 0.938L88.7079 2.485V4.69H89.4989V0H88.5329L87.5249 2.058L87.0559 3.255L86.5939 2.058L85.5859 0H84.6129V4.69H85.4039Z" />
                    </g>
                    <g >
                        <path d="M21.0613 22.1646V11.3799H17.6514V22.1646V25.5446H21.0613H27.1179V22.1646H21.0613Z"  />
                    </g>
                    <g >
                        <path d="M27.1181 8H21.0615V11.38H27.1181V22.1647H30.524V11.38V8H27.1181Z" />
                    </g>
                    <g >
                        <path d="M0 25.9474V8.13672H11.9549V11.3838H3.71034V15.912H11.3419V19.1349H3.71034V25.9514L0 25.9474Z"  />
                    </g>
                    <g>
                        <path d="M40.8002 19.3041V25.9474H37.0898V8.13672H44.4821C48.2532 8.13672 50.766 10.151 50.766 13.7768C50.766 16.6211 49.1909 18.4178 46.6741 19.0141L51.4114 25.9192H47.2018L42.7568 19.2759L40.8002 19.3041ZM40.8002 16.2585H44.2507C46.118 16.2585 47.0922 15.2714 47.0922 13.7929C47.0922 12.3144 46.1261 11.2992 44.2507 11.2992H40.8002V16.2585Z"  />
                    </g>
                    <g >
                        <path d="M65.4659 25.9474L61.0005 14.3167V25.9474H57.4932V8.13672H61.9342L66.7567 20.698L71.5224 8.13672H75.9066V25.9474H72.3709V14.3167L68.0152 25.9474H65.4659Z"  />
                    </g>
                </svg>
            </Wrapper>
            </Link>

    )
}
